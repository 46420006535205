import React from 'react'
import { PageLayout } from '@shared/components/page-layout'
import { PageLayoutOwnProps } from '@shared/components/page-layout/model'
import { Empty } from '@shared/models'
import { NextPageFC } from '@shared/models/next-fc'

import { NotFoundScreen } from '../../screens/not-found'

const Page404: NextPageFC<Empty, PageLayoutOwnProps> = () => NotFoundScreen()

Page404.layout = PageLayout
Page404.headProps = {
	title: 'Запрашиваемый раздел не существует',
}

export default Page404
