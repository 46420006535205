import React from 'react'
import { Container } from '@shared/components/container'
import SadFace from '@shared/svg/sad-face.svg'
import Link from 'next/link'

import styles from './style.module.scss'

export const NotFoundScreen = () => (
	<Container className={styles.wrapper}>
		<SadFace className={styles.icon} />
		<h1>
			Запрашиваемый раздел <br /> не существует
		</h1>
		<div className={styles.goHome}>
			<Link href='/'>Начать с начала</Link>
		</div>
	</Container>
)
